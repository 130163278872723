import * as React from "react";
import { IconProps } from "..";

const Instagram = React.forwardRef<SVGSVGElement, IconProps>(
  ({ title = "instagram", titleId = "instagram", ...props }, svgRef) => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={svgRef}
        aria-labelledby={titleId}
        {...props}
      >
        {title ? <title id={titleId}>{title}</title> : null}
        <path
          d="M12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.125 3.375h-8.25a4.5 4.5 0 00-4.5 4.5v8.25a4.5 4.5 0 004.5 4.5h8.25a4.5 4.5 0 004.5-4.5v-8.25a4.5 4.5 0 00-4.5-4.5z"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.875 8.063a.937.937 0 100-1.875.937.937 0 000 1.875z"
          fill="currentColor"
        />
      </svg>
    );
  }
);
export default Instagram;
